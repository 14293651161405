export enum COMMON_ROUTES {
  HOME = "/",
  HEALTH_FORM = "/form/health-insurance-form",
  CAR_FORM = "/form/car-insurance-form",
  TW_FORM = "/form/two-wheeler-form",
  POS = "/pos",
  TERM_FORM = "/form/term-insurance-form",
  PRIVACY_POLICY = "/privacy-policy",
  TERM_AND_CONDTION = "/terms-and-conditions",
  NEED_HELP = "/need-help",
  DISCLAIMER = "/disclaimer",
  REFUND_AND_CANCELLATION = "/refund-and-cancellation",
  ABOUT_US = "/about-us",
  CONTACT_US = "/contact-us",
  FREE_CLAIM_ASSISTANCE = "/free-claim-assistance",
  CARRIER = "/carriers",
  MCONTACT_US = "/mcontact-us",
  MCOMPLAINT = "/mcomplaint",
  MCAREER = "/mcareer",
  PIR = "/pir",
  PIR_REPORT = "/pir-report",

  PRODUCT_HEALTH = "/health-insurance",
  PRODUCT_CAR = "/car-insurance",
  PRODUCT_TW = "/two-wheeler-insurance",
  PRODUCT_TERM = "/term-insurance",
  CLAIMS = "/claims",
  SITEMAP = "/sitemap",

  PAYMENT_CONFIRMATION = "/payment-confirmation",

  HIFORM = "/home-insurance-form"
}
