import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import "./PIR.scss";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../Component/Common/CustomButton/CustomButton";
import RiskScore from "./RiskScore/RiskScore";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import RKTextField from "../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import {
  FORMAT_DD_MM_YYYY,
  calculateAge,
  calculateRoundOffSumInsured,
  calculateRoundOffSumInsuredTerm,
  calculateShortOff,
  formatAmountToCurrency,
  formatToCurrency,
  formatToCurrencyPir,
  isEmpty,
  totalsumInsuredData,
} from "../../../SupportingFiles/HelpingFunction";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../Store/hooks";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { ADD_TW_DTO } from "../../../DTO/TWDTO/Form/AddTWDTO";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWAddFormServices";
import { toast } from "react-toastify";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { CLIENTS } from "../../../URLCollection/Clients";
import { TCarAddFormResponse } from "../../../Type/TCarSlice";
import { CAR_ADD_FORM_SERVICES } from "../../../Services/CAR/CarAddFormServices";
import { ADD_CAR_DTO } from "../../../Type/Car/form/AddCarDTO";
import { CAR_ROUTES } from "../../../Router/Path/CarRoutes";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_FORM_SERVICES } from "../../../Services/HealthServices/HealthFormServices";
import { TDropdown } from "../../../Type/Common/TDropdown";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { TERM_ROUTES } from "../../../Router/Path/TermRoutes";

function Report() {
  const navigate = useNavigate();
  const [coverageUptoData, setCoverageUptoData] = useState([
    { value: "", label: "" },
  ]);
  const [sumAssuredDataa, setSumAssuredDataa] = useState([
    { value: "", label: "" },
  ]);
  const { PIR } = useAppSelector((state) => state);
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Health);
  const { Term } = useAppSelector((state) => state);

  useEffect(() => {
    if (PIR.PERSONAL.dob.value) {
      // console.log("ooooooooo", PIR.PERSONAL.dob.value);
      //Coverage Upto data
      const start_point: number =
        Number(calculateAge(PIR.PERSONAL.dob.value)) + 5;

      let data: TDropdown[] = [];
      for (let i = start_point; i <= 100; i++) {
        data.push({ value: `${i}`, label: `${i} Years` });
      }

      setCoverageUptoData(data);
      // console.log("setCoverageUptoDatasetCoverageUptoData", data);
      // Sum Assured Data
      const calculateEndPoint = () => {
        const [year, month, date] = PIR.PERSONAL.dob.value.split("-");

        const age: number = calculateAge(`${date}-${month}-${year}`);
        console.log(age, "age");
        // let age = 25;

        if (age >= 18 && age <= 40)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 30;
        if (age >= 41 && age <= 45)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 22;
        if (age >= 46 && age <= 50)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 20;
        if (age >= 51 && age <= 55)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 12;
        if (age >= 56 && age <= 65)
          return (PIR.PERSONAL.incomeAmount.value / 100000) * 10;
        return 0;
      };

      const sumAssuredData: TDropdown[] = [];
      const endPoint: number = calculateEndPoint();

      for (let i = 2; i <= endPoint; i += i < 50 ? 1 : i < 100 ? 10 : 100) {
        sumAssuredData.push({
          value: `${i * 100000}`,
          label: `₹ ${i >= 100 ? i / 100 : i} ${i < 100 ? "Lakh" : "Crores"}`,
        });
        if (i === 100) {
          sumAssuredData.push({
            value: "12500000",
            label: "₹ 1.25 Crores",
          });
        }
      }

      setSumAssuredDataa(sumAssuredData);
    }
  }, [PIR]);

  const [pirFields, setpirFields] = useState<{
    mobile: { value: string; warning: boolean };
  }>({
    mobile: { value: "", warning: false },
  });
  const [healthPercent, setHealthPercent] = useState(0);
  const [termPercent, setTermPercent] = useState(0);
  const roundOffAmount = Math.round(PIR.sumInsured / 10000) * 10000;
  let HtotalAmount;
  let TtotalAmount;
  let Hamount: number, Tamount: number;

  const healthAmount = PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value || 0;
  const termAmount = PIR?.INSURANCEPOLICY.termCoverageAmount?.value || 0;
  const sumInsuredAmount = PIR.sumInsured;
  const termSumInsured = PIR.termSumInsured;
  Hamount = sumInsuredAmount - healthAmount;

  Tamount = termSumInsured - termAmount;
  // console.log("Tamount", Tamount);
  // console.log("termSumInsured", termSumInsured);
  // console.log("termAmount", termAmount);
  useEffect(() => {
    const sumInsuredAmount = PIR.sumInsured;
    if (sumInsuredAmount !== undefined && sumInsuredAmount !== 0) {
      HtotalAmount = healthAmount;
      const Hpercentage = (HtotalAmount / sumInsuredAmount) * 100;
      const roundedPercentage = Math.round(Hpercentage);
      const percentageString = roundedPercentage.toFixed(2);
      const [integerPart, decimalPart] = percentageString.split(".");

      // healthPercent = +integerPart;
      setHealthPercent(+integerPart);
    }
    if (termSumInsured !== undefined && termSumInsured !== 0) {
      TtotalAmount = termAmount;

      const percentage = (TtotalAmount / termSumInsured) * 100;

      const roundedPercentage = Math.round(percentage);

      const percentageString = roundedPercentage.toFixed(2);
      const [integerPart, decimalPart] = percentageString.split(".");

      // termPercent = +integerPart;
      setTermPercent(+integerPart);
    }
  }, [PIR, healthAmount, termAmount, sumInsuredAmount]);

  const getClassification = (healthPercent: number) => {
    if (healthPercent <= 30) {
      return "high";
    } else if (healthPercent <= 60) {
      return "medium";
    } else {
      return "low";
    }
  };
  const getTermClassification = (termPercent: number) => {
    if (termPercent <= 30) {
      return "high";
    } else if (termPercent <= 60) {
      return "medium";
    } else {
      return "low";
    }
  };
  const getRiskMessage = (classification: any) => {
    switch (classification) {
      case "high":
        return "You're in high risk";
      case "medium":
        return "You're in moderate risk";
      case "low":
        return "You're in low risk";
      default:
        return "";
    }
  };
  const getTermRiskMessage = (termclassification: any) => {
    switch (termclassification) {
      case "high":
        return "You're in high risk";
      case "medium":
        return "You're in moderate risk";
      case "low":
        return "You're in low risk";
      default:
        return "";
    }
  };
  const getClassificationColor = (classification: string) => {
    switch (classification) {
      case "high":
        return "#FF3C3C";
      case "medium":
        return "#FF9A3C";
      case "low":
        return "#3CFF3C";
      default:
        return "#000";
    }
  };
  const getTermClassificationColor = (classification: string) => {
    switch (classification) {
      case "high":
        return "#FF3C3C";
      case "medium":
        return "#FF9A3C";
      case "low":
        return "#3CFF3C";
      default:
        return "#000";
    }
  };
  console.log("term percentttt", termPercent);
  const getRoundOffAmountInTerm = () => {
    // console.log(Tamount, "Tamount");
    let roundedAmount = 0;
    // Check if amount is in crores
    if (Tamount >= 10000000) {
      // const roundOffAmount = Math.round(PIR.sumInsured / 10000) * 10000;
      roundedAmount = Math.round(PIR.termSumInsured / 1000000) * 1000000;
      // console.log(roundedAmount, "Rounded amount in crores");
      return roundedAmount;
    }

    // Check if amount is in lakhs
    if (Tamount >= 100000) {
      roundedAmount = Math.round(PIR.termSumInsured / 10000) * 10000;
      // console.log(roundedAmount, "Rounded amount in lakhs");
      return roundedAmount;
    }

    // Amount is less than a lakh, return as is
    // console.log(Tamount, "Amount less than a lakh");
    return roundedAmount;
  };
  const classification = getClassification(healthPercent);
  const termclassification = getTermClassification(termPercent);
  const riskMessage = getRiskMessage(classification);
  const riskTermMessage = getTermRiskMessage(termclassification);
  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  const getPolicyStatus = (policyExpiryDate: string) => {
    const today = new Date();
    const expiryDate = new Date(policyExpiryDate);

    // Calculate the difference in time
    const timeDifference = expiryDate.getTime() - today.getTime();

    // Calculate the difference in days
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    if (dayDifference < 0) {
      return "Expired";
    } else if (dayDifference === 0) {
      return "Expire Today";
    } else {
      return `${dayDifference} days left`;
    }
  };

  const viewPlanHandler = (index: number) => {
    // POST_TW_FORM();

    const vehicleDetail = PIR.HAVEVEHICLE.find((vehicle, i) => i === index);

    if (vehicleDetail?.vehicleType.value === "Tw") {
      POST_TW_FORM(index);
    } else {
      POST_CAR_FORM(index);
    }
  };

  const POST_TW_FORM = (index: number) => {
    const onSuccess = (res: any) => {
      const error = res.results.error;
      const message = res.results.message;
      if (!error) {
        const response = res?.results?.response;
        const error = res?.results?.error;

        if (!error) {
          // window.location.href = `https://${CLIENTS.BIMASTREET_QUOTE}${TW_ROUTES.QUOTE}?type=TW&quote_no=${response.quote_no}`;
          // window.location.href = `https://dev.bimastreet.com/quote/two-wheeler-insurance/two-wheeler-quote?type=TW&quote_no=${response.quote_no}`;
          window.location.href = `${FRONTEND_DOMAIN}${TW_ROUTES.QUOTE}?type=TW&quote_no=${response.quote_no}`;
        }
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = () => {};

    // let data: ADD_TW_DTO | undefined;
    // PIR.HAVEVEHICLE.map((vehicle) => {
    const data: ADD_TW_DTO = {
      twBusinessType: "Rollover",
      twClaimMade: "No",
      twFuelType: PIR.HAVEVEHICLE[index].fuelType.value,
      twMakeModel: PIR.HAVEVEHICLE[index].makeModel.value,
      twMobile: PIR.PERSONAL.mobile.value,
      twPExpiryDate: FORMAT_DD_MM_YYYY(
        `${PIR.HAVEVEHICLE[index].policyExpiryDate.value}`
      ),
      twPreviousInsurer: PIR.HAVEVEHICLE[index].previousInsurer.value,
      twPreviousNCB: "0",
      twRegDate: FORMAT_DD_MM_YYYY(
        `${PIR.HAVEVEHICLE[index].registrationDate.value}`
      ),
      twRegNo: PIR.HAVEVEHICLE[index].regNo.value,
      twRtoCode: "",
      twVariantCC: PIR.HAVEVEHICLE[index].variantCC.value,
      // };
    };

    if (data) {
      TW_ADD_FORM_SERVICES.ADD_FORM_DATA(onSuccess, onError, data);
    } else {
      console.error("No vehicle data to submit");
    }
  };
  const POST_CAR_FORM = (index: number) => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      const message = results.message;

      // setLoader(false);
      if (!error) {
        const response: TCarAddFormResponse = results.response;

        // window.location.href = `https://${CLIENTS.BIMASTREET_QUOTE}${CAR_ROUTES.QUOTE}?type=CAR&quote_no=${response.quote_no}`;
        // window.location.href = `https://dev.bimastreet.com/quote/car-insurance/car-quote?type=CAR&quote_no=${response.quote_no}`;
        window.location.href = `${FRONTEND_DOMAIN}${CAR_ROUTES.QUOTE}?type=CAR&quote_no=${response.quote_no}`;
        // window.location.href = `${FRONTEND_DOMAIN}${CAR_ROUTES.QUOTE}?type=CAR&quote_no=${response.quote_no}`;
      } else {
        toast.error(`${message}`);
        console.log(message);
      }
    };

    const onError = (err: any) => {
      // setLoader(false);
      console.log(err);
    };
    // dispatch(
    //   UserProfileSlice.actions.setAccountUserDataMultipleKey([
    //     { key: "name", value: ADD_FORM.name.value },
    //     { key: "mobile", value: ADD_FORM.mobile.value },
    //   ])
    // );

    // let data: ADD_CAR_DTO | undefined;
    // PIR.HAVEVEHICLE.map((vehicle) => {
    const data: ADD_CAR_DTO = {
      carBusinessType: "Rollover",
      carClaimMade: "No",
      carFuelType: PIR.HAVEVEHICLE[index].fuelType.value,
      carMakeModel: PIR.HAVEVEHICLE[index].makeModel.value,
      carMobile: PIR.PERSONAL.mobile.value,
      // carPExpiryDate: vehicle.policyExpiryDate.value,
      carPExpiryDate: FORMAT_DD_MM_YYYY(
        `${PIR.HAVEVEHICLE[index].policyExpiryDate.value}`
      ),
      carPreviousInsurer: PIR.HAVEVEHICLE[index].previousInsurer.value,
      carPreviousNCB: "0",
      // carRegDate: FORMAT_DD_MM_YYYY(`${ADD_FORM.reg_date.value}`),
      carRegDate: FORMAT_DD_MM_YYYY(
        `${PIR.HAVEVEHICLE[index].registrationDate.value}`
      ),
      carRegNo: PIR.HAVEVEHICLE[index].regNo.value,
      carRtoCode: "",
      carVariantCC: PIR.HAVEVEHICLE[index].variantCC.value,
    };
    // };

    if (data) {
      CAR_ADD_FORM_SERVICES.GENERATE_QUOTE_NO(onSuccess, onError, data);
    } else {
      console.error("No vehicle data to submit");
    }
  };
  // console.log("healthPercent====>", healthPercent);
  // const backHandler = () => {
  //   // console.log("backHandlerbackHandlerbackHandlerbackHandler");
  //   navigate(COMMON_ROUTES.PIR);
  // };
  const healthQuotation = () => {
    // console.log("health quotation clicked");
    const onSuccess = (res: any) => {
      // setShowLoader(false);
      // debugger;

      const results = res.response;
      const error = res.error;
      // console.log("ressss healthh=====>", results);
      if (!error) {
        let dataResponse: any = {
          quote_no: results?.quote_no,
          sum_insured: results.sum_insured,
          module: results?.module,
          insurance_type: results?.insurance_type,
          term: "1",
          cover: results?.cover,
          city: results?.city,
          state: results?.state,
          child: results?.child,
          adult: results?.adult,
          mobile: results?.mobile,
          pincode: results?.pincode,
        };
        // dispatch(
        //   HealthSlice.actions.BULK_UPLOAD({
        //     ...Health,
        //     ADD_FORM: data,
        //     ADD_FORM_RESPONSE: dataResponse,
        //   })
        // );

        // window.location.href = `https://${CLIENTS.BIMASTREET_QUOTE}${HEALTH_ROUTE.QOUTE_PAGE}?type=HP&quote_no=${dataResponse.quote_no}`;
        // window.location.href = `https://dev.bimastreet.com/quote/health-insurance/health-quote?type=HP&quote_no=${dataResponse.quote_no}`;
        window.location.href = `${FRONTEND_DOMAIN}${HEALTH_ROUTE.QOUTE_PAGE}?type=HP&quote_no=${dataResponse.quote_no}`;

        // navigate(HEALTH_ROUTE.QOUTE_PAGE);
      } else {
        toast.error("Something went wrong");
      }
    };
    const onError = (error: any) => {
      // setShowLoader(false);
      console.log("error", error);
    };

    // Define the parameters outside the JSX return statement
    // const { son_count, daughter_count } = data;
    // const total_sons = parseInt(son_count.value);
    // const total_daughters = parseInt(daughter_count.value);

    let params: any = {
      // healthSumInsured: (Math.round(Hamount / 100000) * 100000).toString(),
      // healthSumInsured: calculateRoundOffSumInsured(
      //   formatToCurrency(
      //     Number(
      //       PIR.sumInsured - PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value
      //     )
      //   ),
      //   DROPDOWN_DATA.SUM_INSURED
      // ),
      healthSumInsured: totalsumInsuredData(
        calculateShortOff(
          formatToCurrencyPir(
            Number(
              calculateRoundOffSumInsured(
                formatToCurrency(Number(PIR.sumInsured)),
                DROPDOWN_DATA.SUM_INSURED
              )
            )
          ),
          formatToCurrencyPir(
            Number(PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value)
          )
        )
      ),
      healthCover: `${PIR.RELATION.adult.value}${PIR.RELATION.childrenCount.value}C`,
      healthAdult: `${PIR.RELATION.adult.value === "2A" ? "2" : "1"}`,
      children: PIR.RELATION.childrenCount.value.toString(),
      healthAge: FORMAT_DD_MM_YYYY(PIR.PERSONAL.dob.value),
      healthRelation: "self",
      healthGender: PIR.PERSONAL.gender.value === "Female" ? "F" : "M",
      health2Age: FORMAT_DD_MM_YYYY(PIR.RELATION.second_adult_dob.value),
      health2Relation: PIR.RELATION.relation.value,
      healthName: "",
      healthMobile: PIR.PERSONAL.mobile.value,
      healthPincode: PIR.PERSONAL.pincode.value,
      childgender_1: PIR.RELATION.child_1_relation.value == "Son" ? "M" : "F",
      childrelation_1: PIR.RELATION.child_1_relation.value.toLowerCase(),
      childage_1: FORMAT_DD_MM_YYYY(PIR.RELATION.child_1_dob.value),
      childgender_2: PIR.RELATION.child_2_relation.value == "Son" ? "M" : "F",
      childrelation_2: PIR.RELATION.child_2_relation.value.toLowerCase(),
      childage_2: FORMAT_DD_MM_YYYY(PIR.RELATION.child_2_dob.value),
      childgender_3: PIR.RELATION.child_3_relation.value == "Son" ? "M" : "F",
      childrelation_3: PIR.RELATION.child_3_relation.value.toLowerCase(),
      childage_3: FORMAT_DD_MM_YYYY(PIR.RELATION.child_3_dob.value),
      childgender_4: PIR.RELATION.child_4_relation.value == "Son" ? "M" : "F",
      childrelation_4: PIR.RELATION.child_4_relation.value.toLowerCase(),
      childage_4: FORMAT_DD_MM_YYYY(PIR.RELATION.child_4_dob.value),
    };

    HEALTH_FORM_SERVICES.ADD_HEALTH_FORM(onSuccess, onError, params);
  };
  const callBackHandler = () => {
    let local_mobile_data = { ...pirFields.mobile };

    local_mobile_data = {
      ...local_mobile_data,
      warning: !local_mobile_data.value,
    };
    setpirFields({
      ...pirFields,
      mobile: local_mobile_data,
    });
  };
  const sumAssuredDataOnQuote = (data: any) => {
    console.log("data", data);
    // console.log("data", sumAssuredDataa);
    const value = data.split(" ")[1];
    const currency = data.split(" ")[2];
    console.log("data", value, currency);
    if (currency == "Crore" || currency == "Crores") {
      let value1;
      if (value.includes(".")) {
        const [integerVal, decimalVal] = value.split(".");
        if (decimalVal.length > 1) {
          value1 = +`${integerVal}${decimalVal}00000`;
        } else {
          value1 = +`${integerVal}${decimalVal}000000`;
        }
      } else {
        value1 = +`${value}0000000`;
      }
      console.log("data", value1);
      for (const val of sumAssuredDataa) {
        const [value2, label] = Object.entries(val);
        const item = +value2[1];
        // console.log("dataaaa", item);
        if (item >= value1) {
          console.log("dataaaa======bbbb", item);
          return item;
        }
      }
    } else {
      console.log("lakh data", value);

      return `${value}00000`;
    }
  };
  const termQuotation = () => {
    const onSuccess = (res: any) => {
      console.log("ress",res)
      const response = res.response;
      // console.log("resposne in term ", response);
      const dataResponse: any = {
        quote_no: response?.quote_no,
        // sum_insured: calculateRoundOffSumInsuredTerm(
        //   formatToCurrency(
        //     Number(
        //       PIR.termSumInsured -
        //         PIR?.INSURANCEPOLICY?.termCoverageAmount?.value
        //     )
        //   ),
        //   sumAssuredDataa
        // ),
        sum_insured: sumAssuredDataOnQuote(
          calculateShortOff(
            formatToCurrencyPir(
              Number(
                calculateRoundOffSumInsuredTerm(
                  formatToCurrency(Number(PIR.termSumInsured)),
                  sumAssuredDataa
                )
              )
            ),
            formatToCurrencyPir(
              Number(PIR?.INSURANCEPOLICY?.termCoverageAmount?.value)
            )
          )
        ),
      };
      console.log("val22", dataResponse);
      // window.location.href = `https://dev.bimastreet.com/quote/term-insurance/term-quote?type=TM&quote_no=${dataResponse.quote_no}&sa=${dataResponse.sum_insured}`;
      window.location.href = `${FRONTEND_DOMAIN}${TERM_ROUTES.QUOTE}?type=TM&quote_no=${dataResponse.quote_no}&sa=${dataResponse.sum_insured}`;

      // setLoader(false);
      // window.location.href = `${FRONTEND_DOMAIN}${TERM_ROUTES.QUOTE}?type=TM&quote_no=${response.quote_no}`;
    };
    const onError = (err: any) => {
      console.log("err", err);
      // setLoader(false);
    };

    let params: any = {
      lifeInsurance_module: "TermPlan",
      lifeInsurance_type: "Term Plan",
      termSmoker: PIR.INSURANCEPOLICY.smoke.value == "Yes" ? "Y" : "N",
      termAnnualIncome: PIR.PERSONAL.incomeAmount.value.toString(),
      termAnnualIncomeOther: PIR.PERSONAL.incomeAmount.value.toString(),
      termGender: PIR.PERSONAL.gender.value == "Female" ? "F" : "M",
      termDob: `${FORMAT_DD_MM_YYYY(PIR.PERSONAL.dob.value)}`,
      termName: PIR.PERSONAL.firstname.value,
      termMobile: PIR.PERSONAL.mobile.value,
      termPincode: PIR.PERSONAL.pincode.value,
    };

    // dispatch(
    //   UserProfileSlice.actions.setAccountUserDataMultipleKey([
    //     { key: "name", value: data.name.value },
    //     { key: "pincode", value: data.pincode.value },
    //     { key: "gender", value: data.gender.value },
    //     { key: "age", value: moment(data.age.value).format("DD-MM-YYYY") },
    //     { key: "mobile", value: data.mobile.value },
    //   ])
    // );
    // setLoader(true);
    TERM_SERVICES.addTermFormData(onSuccess, onError, params);
  };
  return (
    <Box
      className="pirWrapper"
      sx={{
        paddingTop: "160px",
      }}
    >
      <Navbar />

      <Box
        sx={{
          background: "linear-gradient(180deg, #ffffff 0%, #EDF4FD 100%)",
          paddingBottom: "40px",
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} md={6}>
            <h2>Welcome to your kind of insurance planning!</h2>
            <p className="mt-4">
              Simplify the process Look for insurance providers that offer a
              streamlined and user-friendly experience. Online platforms and
              digital tools can make it easier to research, compare, and
              purchase insurance policies.
            </p>
          </Grid>
          <Grid xs={12} md={6} textAlign="center">
            <img
              src="./images/insurance-planning.svg"
              width="100%"
              style={{ maxWidth: "312px" }}
              alt="img"
            />
          </Grid>
        </Grid>
      </Box>

      {/* Health Insurance */}

      {sumInsuredAmount > healthAmount ? (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h2 className="mt-6">Health Insurance!</h2>
            <p className="mt-4">
              Having a comprehensive health cover will guarantee you peace of
              mind and will help to keep your savings intact in case of a
              medical emergency. So, plan for it and stay financially prepared
              for the worst. Give yourself and your family the protection of
              health insurance and stay stress-free of rising medical costs.
            </p>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0px 12px 32px rgba(32, 56, 100, 0.12)",
                borderRadius: "24px",
                padding: "24px",
                textAlign: "center",
              }}
            >
              <ul className="indications">
                <li className={classification === "high" ? "high" : ""}>
                  High
                </li>
                <li className={classification === "medium" ? "medium" : ""}>
                  Medium
                </li>
                <li className={classification === "low" ? "low" : ""}>Low</li>
              </ul>
              <Box
                display="flex"
                alignItems="center"
                gap="24px"
                sx={{
                  "@media (max-width: 1100px)": {
                    display: "block",
                  },
                }}
              >
                <Box
                  width="300px"
                  sx={{
                    "@media (max-width: 1100px)": {
                      margin: "0px auto",
                    },
                  }}
                >
                  <RiskScore
                    class_name="svg-pi-wrapper riskmap"
                    progress_one={healthPercent}
                  ></RiskScore>
                  <Box
                    display="inline-flex"
                    gap="12px"
                    position="relative"
                    top="-86px"
                  >
                    <p>₹ 0</p>
                    {/* <p style={{ color: "#FF9A3C", fontSize: "12px" }}>
                    You're in moderate risk
                  </p> */}
                    <p
                      style={{
                        color: getClassificationColor(classification),
                        fontSize: "12px",
                      }}
                    >
                      {riskMessage}
                    </p>
                    <p>
                      {formatToCurrencyPir(
                        Number(
                          calculateRoundOffSumInsured(
                            formatToCurrency(Number(PIR.sumInsured)),
                            DROPDOWN_DATA.SUM_INSURED
                          )
                        )
                      )}
                    </p>
                    {/* <p>{formatToCurrencyPir(Number(PIR.sumInsured))}</p> */}
                    {/* <p>₹10 cr</p> */}
                  </Box>
                </Box>

                <ul className="coverageInfo">
                  <li>
                    <span>Existing</span> coverage:{" "}
                    {formatToCurrencyPir(
                      Number(PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value)
                    )}
                  </li>
                  <li>
                    <span>Recommended</span> coverage:{" "}
                    {/* {formatAmountToCurrency(Number(Hamount))} */}
                    {formatToCurrencyPir(
                      Number(
                        calculateRoundOffSumInsured(
                          formatToCurrency(Number(PIR.sumInsured)),
                          DROPDOWN_DATA.SUM_INSURED
                        )
                      )
                    )}
                  </li>
                </ul>
              </Box>
              <h5 className="shortof">
                You're short of{" "}
                {/* <span> {formatAmountToCurrency(Number(roundOffAmount))}</span> */}
                <span>
                  {" "}
                  {/* {formatToCurrencyPir(
                    Number(
                      calculateRoundOffSumInsured(
                        formatToCurrency(
                          Number(
                            PIR.sumInsured -
                              PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value
                          )
                        ),
                        DROPDOWN_DATA.SUM_INSURED
                      )
                    )
                  )} */}
                  {calculateShortOff(
                    formatToCurrencyPir(
                      Number(
                        calculateRoundOffSumInsured(
                          formatToCurrency(Number(PIR.sumInsured)),
                          DROPDOWN_DATA.SUM_INSURED
                        )
                      )
                    ),
                    formatToCurrencyPir(
                      Number(PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value)
                    )
                  )}
                </span>
              </h5>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <h4 className="mb-4">
              You need extra Health life cover :{" "}
              <span
                style={{
                  fontSize: "24px",
                  backgroundColor: "#F1F6FE",
                  borderRadius: "12px",
                  padding: "14px 18px",
                  display: "inline-block",
                }}
              >
                {/* ₹ {Hamount} */}
                {/* {formatToCurrencyPir(
                  Number(
                    calculateRoundOffSumInsured(
                      formatToCurrency(
                        Number(
                          PIR.sumInsured -
                            PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value
                        )
                      ),
                      DROPDOWN_DATA.SUM_INSURED
                    )
                  )
                )} */}
                {calculateShortOff(
                  formatToCurrencyPir(
                    Number(
                      calculateRoundOffSumInsured(
                        formatToCurrency(Number(PIR.sumInsured)),
                        DROPDOWN_DATA.SUM_INSURED
                      )
                    )
                  ),
                  formatToCurrencyPir(
                    Number(PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value)
                  )
                )}
              </span>
            </h4>
            <p>
              You surely know health is wealth, no wonder you have sufficient
              coverage for yourself & your family. We recommend a minimum sum
              insured of{" "}
              {formatToCurrencyPir(
                Number(
                  calculateRoundOffSumInsured(
                    formatToCurrency(
                      Number(
                        PIR.sumInsured -
                          PIR?.INSURANCEPOLICY?.healthCoverageAmount?.value
                      )
                    ),
                    DROPDOWN_DATA.SUM_INSURED
                  )
                )
              )}{" "}
              This is key given healthcare costs only tend to get ginormous by
              the day.
            </p>
            <p className="recommendation">
              {PIR.PERSONAL.firstname.value}, get a complete healthcare plan &
              shield your family against all odds.
            </p>

            <Box
              className="ctaBtn"
              display="flex"
              gap="24px"
              justifyContent="center"
            >
              {/* <Buttons
              onClickFunction={backHandler}
              class_name="textMdBtn"
              text_name="Go back to Details"
            /> */}
              <Buttons
                onClickFunction={healthQuotation}
                class_name="greenMdBtn roundedBtn"
                text_name="Compare Insurance Plans"
              />
            </Box>

            <p
              style={{
                textAlign: "center",
                color: "#4f4f4f",
                marginTop: "24px",
              }}
            >
              You will be able to save upto INR 2000 in taxes under Sec 80C.
            </p>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          {" "}
          <Grid xs={12}>
            <h2 className="mt-6">Health Insurance! </h2>
            {/* <p className="mt-4">
          Having a comprehensive health cover will guarantee you peace of
          mind and will help to keep your savings intact in case of a
          medical emergency. So, plan for it and stay financially prepared
          for the worst. Give yourself and your family the protection of
          health insurance and stay stress-free of rising medical costs.
        </p> */}
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0px 12px 32px rgba(32, 56, 100, 0.12)",
                borderRadius: "24px",
                padding: "24px",
                textAlign: "center",
              }}
            >
              <p>
                {" "}
                Congratulations, We are pleased to inform you that your existing
                coverage exceeds the recommended coverage. Your proactive
                approach to securing comprehensive health insurance is
                commendable and ensures that you are well-protected.{" "}
              </p>
            </Box>
          </Grid>
        </Grid>
      )}

      {/* Term Insurance */}

      {termSumInsured > termAmount ? (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h2 className="mt-6">Term Life Insurance! </h2>
            <p className="mt-4">
              Having a comprehensive health cover will guarantee you peace of
              mind and will help to keep your savings intact in case of a
              medical emergency. So, plan for it and stay financially prepared
              for the worst. Give yourself and your family the protection of
              health insurance and stay stress-free of rising medical costs.
            </p>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0px 12px 32px rgba(32, 56, 100, 0.12)",
                borderRadius: "24px",
                padding: "24px",
                textAlign: "center",
              }}
            >
              <ul className="indications">
                <li className={termclassification === "high" ? "high" : ""}>
                  High
                </li>
                <li className={termclassification === "medium" ? "medium" : ""}>
                  Medium
                </li>
                <li className={termclassification === "low" ? "low" : ""}>
                  Low
                </li>
              </ul>
              <Box
                display="flex"
                alignItems="center"
                gap="24px"
                sx={{
                  "@media (max-width: 1100px)": {
                    display: "block",
                  },
                }}
              >
                <Box
                  width="300px"
                  sx={{
                    "@media (max-width: 1100px)": {
                      margin: "0px auto",
                    },
                  }}
                >
                  <RiskScore
                    class_name="svg-pi-wrapper riskmap"
                    progress_one={termPercent}
                  ></RiskScore>
                  <Box
                    display="inline-flex"
                    gap="12px"
                    position="relative"
                    top="-86px"
                  >
                    <p>₹ 0</p>
                    {/* <p style={{ color: "#FF9A3C", fontSize: "12px" }}>
              You're in moderate riskbisma
            </p> */}
                    <p
                      style={{
                        color: getTermClassificationColor(termclassification),
                        fontSize: "12px",
                      }}
                    >
                      {riskTermMessage}
                    </p>
                    {/* <p>{formatToCurrencyPir(Number(PIR.termSumInsured))}</p> */}
                    <p>
                      {formatToCurrencyPir(
                        Number(
                          calculateRoundOffSumInsuredTerm(
                            formatToCurrency(Number(PIR.termSumInsured)),
                            sumAssuredDataa
                          )
                        )
                      )}
                    </p>
                  </Box>
                </Box>

                <ul className="coverageInfo">
                  <li>
                    <span>Existing</span> coverage:{" "}
                    {formatToCurrencyPir(
                      Number(PIR?.INSURANCEPOLICY?.termCoverageAmount?.value)
                    )}
                  </li>
                  <li>
                    <span>Recommended</span> coverage:
                    {/* {formatAmountToCurrency(Number(Tamount))} */}
                    {formatToCurrencyPir(
                      Number(
                        calculateRoundOffSumInsuredTerm(
                          formatToCurrency(Number(PIR.termSumInsured)),
                          sumAssuredDataa
                        )
                      )
                    )}
                  </li>
                </ul>
              </Box>
              <h5 className="shortof">
                You're short of{" "}
                <span>
                  {/* {formatToCurrencyPir(
            PIR.termSumInsured -
              PIR?.INSURANCEPOLICY?.termCoverageAmount?.value
          )} */}
                  {/* {formatToCurrencyPir(
                    Number(
                      calculateRoundOffSumInsuredTerm(
                        formatToCurrency(
                          Number(
                            PIR.termSumInsured -
                              PIR?.INSURANCEPOLICY?.termCoverageAmount?.value
                          )
                        ),
                        sumAssuredDataa
                      )
                    )
                  )} */}
                  {calculateShortOff(
                    formatToCurrencyPir(
                      Number(
                        calculateRoundOffSumInsuredTerm(
                          formatToCurrency(Number(PIR.termSumInsured)),
                          sumAssuredDataa
                        )
                      )
                    ),
                    formatToCurrencyPir(
                      Number(PIR?.INSURANCEPOLICY?.termCoverageAmount?.value)
                    )
                  )}
                </span>
                {/* <span>{formatAmountToCurrency(getRoundOffAmountInTerm())}</span> */}
                {/* <span>{formatAmountToCurrency(Number(roundOffAmount))}</span> */}
              </h5>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <h4 className="mb-4">
              You need extra life cover:{" "}
              <span
                style={{
                  fontSize: "24px",
                  backgroundColor: "#F1F6FE",
                  borderRadius: "12px",
                  padding: "14px 18px",
                  display: "inline-block",
                }}
              >
                {/* {formatToCurrency(Number(Tamount))} */}
                {/* {formatToCurrencyPir(
                  Number(
                    calculateRoundOffSumInsuredTerm(
                      formatToCurrency(
                        Number(
                          PIR.termSumInsured -
                            PIR?.INSURANCEPOLICY?.termCoverageAmount?.value
                        )
                      ),
                      sumAssuredDataa
                    )
                  )
                )} */}
                {calculateShortOff(
                  formatToCurrencyPir(
                    Number(
                      calculateRoundOffSumInsuredTerm(
                        formatToCurrency(Number(PIR.termSumInsured)),
                        sumAssuredDataa
                      )
                    )
                  ),
                  formatToCurrencyPir(
                    Number(PIR?.INSURANCEPOLICY?.termCoverageAmount?.value)
                  )
                )}
              </span>
            </h4>
            <p>
              Life is unpredictable, and term insurance provides essential
              financial protection for your family in case of your untimely
              demise. We recommend a minimum sum assured of{" "}
              {formatToCurrencyPir(
                Number(
                  calculateRoundOffSumInsuredTerm(
                    formatToCurrency(
                      Number(
                        PIR.termSumInsured -
                          PIR?.INSURANCEPOLICY?.termCoverageAmount?.value
                      )
                    ),
                    sumAssuredDataa
                  )
                )
              )}{" "}
              This amount covers your family's daily expenses, education costs,
              and outstanding debts, ensuring their financial stability.
            </p>
            {/* <span
        style={{
          fontSize: "24px",
          backgroundColor: "#F1F6FE",
          fontWeight: "500",
          borderRadius: "12px",
          padding: "14px 18px",
          display: "inline-block",
          margin: "16px 0px",
        }}
      >
        ₹ 70 Lacs
      </span> */}
            <p>
              {/* We recommend a minimum sum assured of Rs{" "}
        {formatAmountToCurrency(Number(Tamount))} This amount covers your
        family's daily expenses, education costs, and outstanding debts,
        ensuring their financial stability. */}
            </p>
            <p className="recommendation">
              {PIR?.PERSONAL?.firstname?.value}, get a complete termcare plan &
              shield your family against all odds.
            </p>

            <Box
              className="ctaBtn"
              display="flex"
              gap="24px"
              justifyContent="center"
            >
              {/* <Buttons
        onClickFunction={() => {}}
        class_name="textMdBtn"
        text_name="Go back to Details"
      /> */}
              <Buttons
                onClickFunction={termQuotation}
                class_name="greenMdBtn roundedBtn"
                text_name="Compare Insurance Plans"
              />
            </Box>

            <p
              style={{
                textAlign: "center",
                color: "#4f4f4f",
                marginTop: "24px",
              }}
            >
              You will be able to save upto INR 2000 in taxes under Sec 80C.
            </p>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          {" "}
          <Grid xs={12}>
            <h2 className="mt-6">Term Life Insurance! </h2>
            {/* <p className="mt-4">
              Having a comprehensive health cover will guarantee you peace of
              mind and will help to keep your savings intact in case of a
              medical emergency. So, plan for it and stay financially prepared
              for the worst. Give yourself and your family the protection of
              health insurance and stay stress-free of rising medical costs.
            </p> */}
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0px 12px 32px rgba(32, 56, 100, 0.12)",
                borderRadius: "24px",
                padding: "24px",
                textAlign: "center",
              }}
            >
              <p>
                {" "}
                Congratulations, We are pleased to inform you that your existing
                coverage exceeds the recommended coverage. Your proactive
                approach to securing comprehensive term insurance is commendable
                and ensures that you are well-protected.{" "}
              </p>
            </Box>
          </Grid>
        </Grid>
      )}
      {/* Motor Insurance */}

      {PIR.HAVEANYVEHICLE.haveAnyVehicle.value === "Yes" ? (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h2 className="mt-6">Motor Insurance!</h2>
            <p className="mt-4">
              Thank you for choosing our motor insurance advisory service. We
              genuinely appreciate the opportunity to assist you with your motor
              insurance needs. Your trust in our expertise is valued, and we are
              committed to providing you with a seamless and tailored insurance
              experience.
            </p>
          </Grid>
          <Grid xs={12}>
            {/* <h5>Attention, your policy has expired.</h5> */}
            {PIR.HAVEVEHICLE.map((vehcile, index) => {
              const policyStatus = getPolicyStatus(
                vehcile.policyExpiryDate.value
              );
              return (
                <Box className="motorCard">
                  <Grid container spacing={3} alignItems="center">
                    {vehcile.vehicleType.value === "Tw" ? (
                      <Grid xs={5} sm={3} md={3}>
                        <img src="./images/bike_product_icon.svg" />
                      </Grid>
                    ) : (
                      <Grid xs={5} sm={3} md={3}>
                        <img src="./images/car_product_icon.svg" />
                      </Grid>
                    )}

                    <Grid xs>
                      <h6 className="mb-4">{vehcile.makeModel.value}</h6>
                      <p>{vehcile.variantCC.value}</p>
                    </Grid>
                    <Grid xs={12} md="auto" display="inline-flex" gap="24px">
                      <p
                        style={{
                          border: "1px solid #ea4559",
                          borderRadius: "4px",
                          borderColor:
                            policyStatus === "Expired" ||
                            policyStatus === "Expired Today"
                              ? "#ea4559"
                              : "black",
                          color:
                            policyStatus === "Expired" ||
                            policyStatus === "Expired Today"
                              ? "#ea4559"
                              : "black",
                          padding: "4px 12px",
                          display: "inline-flex",
                          alignSelf: "center",
                          fontWeight: "600",
                          alignItems: "center",
                        }}
                      >
                        {policyStatus !== "Expired" &&
                        policyStatus !== "Expired Today" ? (
                          <AccessAlarmsIcon style={{ height: "16px" }} />
                        ) : null}
                        {policyStatus}
                      </p>

                      <Box className="ctaBtn">
                        <Buttons
                          onClickFunction={() => viewPlanHandler(index)}
                          class_name="greenMdBtn roundedBtn"
                          text_name="View Plan"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
            {/* <Box className="motorCard">
            <Grid container spacing={3} alignItems="center">
              <Grid xs={5} sm={3} md={3}>
                <img src="./images/bike_product_icon.svg" />
              </Grid>
              <Grid xs>
                <h6 className="mb-4">Honda Activa</h6>
                <p>Petrol, 125 cc</p>
              </Grid>
              <Grid xs={12} md="auto" display="inline-flex" gap="24px">
                <p
                  style={{
                    border: "1px solid #ea4559",
                    borderRadius: "4px",
                    color: "#ea4559",
                    padding: "4px 12px",
                    display: "inline-block",
                    alignSelf: "center",
                    fontWeight: "600",
                  }}
                >
                  Expired
                </p>
                <Box className="ctaBtn">
                  <Buttons
                    onClickFunction={() => {}}
                    class_name="greenMdBtn roundedBtn"
                    text_name="View Plan"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box> */}
          </Grid>
          {/* <Grid xs={12}>
          <h5>Attention, your policy is about to expire.</h5>
          <Box className="motorCard">
            <Grid container spacing={3} alignItems="center">
              <Grid xs={5} sm={3} md={3}>
                <img src="./images/car_product_icon.svg" />
              </Grid>
              <Grid xs>
                <h6 className="mb-4">Hyundai Creta</h6>
                <p>Petrol, Asta 1250 cc</p>
              </Grid>
              <Grid xs={12} md="auto" display="inline-flex" gap="24px">
                <p
                  style={{
                    border: "1px solid #000",
                    borderRadius: "4px",
                    padding: "4px 12px",
                    display: "inline-flex",
                    alignItems: "center",
                    alignSelf: "center",
                    fontWeight: "600",
                  }}
                >
                  <AccessAlarmsIcon style={{ height: "16px" }} />
                  45 days left
                </p>
                <Box className="ctaBtn">
                  <Buttons
                    onClickFunction={() => {}}
                    class_name="greenMdBtn roundedBtn"
                    text_name="View Plan"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid> */}
        </Grid>
      ) : // <Grid>
      //   {" "}
      //   <Grid xs={12}>
      //     <h2 className="mt-6">Motor Insurance!</h2>
      //     <p className="mt-4">
      //       Thank you for choosing our motor insurance advisory service. We
      //       genuinely appreciate the opportunity to assist you with your motor
      //       insurance needs. Your trust in our expertise is valued, and we are
      //       committed to providing you with a seamless and tailored insurance
      //       experience.
      //     </p>
      //   </Grid>
      //   <Grid xs={12} md={6}>
      //     <Box
      //       sx={{
      //         backgroundColor: "#fff",
      //         boxShadow: "0px 12px 32px rgba(32, 56, 100, 0.12)",
      //         borderRadius: "24px",
      //         padding: "24px",
      //         textAlign: "center",
      //       }}
      //     >
      //       <p> No Quotes found!.Because you don't have any vehicle.</p>
      //     </Box>
      //   </Grid>
      // </Grid>
      null}

      {/* Home Insurance */}
      {/* {PIR.OWNHOUSE.ownHouse.value !== "" ? (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h2 className="mt-6">Home Insurance!</h2>
            <p className="mt-4">
              Home insurance, also known as homeowner's insurance or property
              insurance, is a type of insurance policy that provides coverage
              for private residences. It typically combines various personal
              insurance protections, which can include losses occurring to one's
              home, its contents, loss of use (additional living expenses), or
              liability insurance for accidents that may happen at the home.
            </p>
            <Box
              sx={{
                boxShadow: "0px 12px 23px rgba(32, 56, 100, 0.12)",
                borderRadius: "12px",
                padding: "12px",
                margin: "24px 0px",
              }}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid sm={12} md={6}>
                  <h5>Do you require assistance?</h5>
                  <p>
                    We're here and ready to assist you with anything you need.
                  </p>
                  <Box maxWidth="340px" marginTop="20px" marginBottom="20px">
                    <RKTextField
                      class_name="inputField"
                      title={"Mobile"}
                      value={pirFields.mobile.value}
                      attrName={["mobile", "value", pirFields, setpirFields]}
                      value_update={fieldsUpdateState}
                      warn_status={pirFields.mobile.warning}
                      error_message={
                        isEmpty(pirFields.mobile.value)
                          ? "Enter Mobile Number"
                          : "Enter valid mobile number"
                      }
                      max_length={10}
                      validation_type="NUMBER"
                    />
                  </Box>
                  <Box className="ctaBtn">
                    <Buttons
                      onClickFunction={callBackHandler}
                      class_name="greenMdBtn roundedBtn"
                      text_name="Request call back"
                    />
                  </Box>
                </Grid>
                <Grid sm={12} md={6} textAlign="center">
                  <img
                    src="./images/assistance.svg"
                    width="100%"
                    style={{ maxWidth: "320px" }}
                    alt="img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : null} */}

      {/* Tips */}
      <Grid container spacing={3} marginTop="32px">
        <Grid xs={12}>
          <h5>
            Certainly! Here are some tips to help you ace your insurance
            planning:
          </h5>
        </Grid>
        <Grid xs={12} display="flex" gap="24px" alignItems="center">
          <img src="./images/plannings.svg" width="150px" />
          <p>
            Health insurance for everyone: Health insurance is essential for
            everyone, regardless of age or income. It helps cover medical
            expenses and provides access to quality healthcare. Consider the
            specific health needs of each family member, including your parents,
            and choose a health insurance plan that offers comprehensive
            coverage.
          </p>
        </Grid>
        <Grid xs={12} display="flex" gap="24px" alignItems="center">
          <img src="./images/plannings2.svg" width="150px" />
          <p>
            When purchasing term insurance or any other type of insurance, it is
            always advisable to carefully read and understand the terms and
            conditions of the policy. If you have any doubts or questions, it is
            recommended to seek clarification from the insurance provider or
            consult with a professional advisor who can guide you through the
            process.
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Report;
