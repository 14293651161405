import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Link, Slider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Buttons from "../../../../Component/Common/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { useAppSelector } from "../../../../Store/hooks";
import {
  formatToCurrency,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { TTermAddForm } from "../../../../Type/Term/TTermAddForm";
import "./../../../../CommonSCSS/Forms.scss";

const HomeInsuranceForm = ({}: {}) => {
  // const { DROPDOWN_DATA } = useAppSelector((state) => state.Term);
  // const DATA = useAppSelector((state) => state.CommonDropdownData);
  const navigate = useNavigate();

  // // Screen height is more than add space on top
  const [addSpacing, setAddSpacing] = useState(false);
  const [pageStatus, setPageStatus] = useState<"1" | "2" | "3">("1");
  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;
      setAddSpacing(screenHeight > 700);
    };
    // Initial check on mount
    handleResize();
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [youAreStatus, setYouAreStatus] = useState<"owner" | "tenant">("owner");
  const [youHaveStatus, setYouHaveStatus] = useState<
    "flat" | "independentHouse"
  >("independentHouse");
  const [siValueTypes, setSIvalueTypes] = useState<
    "building" | "household" | "buildingHousholdItems"
  >("building");

  return (
    <Box className="productForm">
      <Box className="backnavigate">
        <Grid container columnSpacing={3} alignItems={"center"}>
          <Grid xs={12}>
            <Link
              className="backStep"
              onClick={() => {
                pageStatus === "2"
                  ? setPageStatus("1")
                  : pageStatus === "3"
                  ? setPageStatus("2")
                  : navigate(COMMON_ROUTES.HOME);
              }}
            >
              <ChevronLeftIcon /> Back
            </Link>
          </Grid>
        </Grid>
      </Box>
      <div
        className={`formWrapper homeinsurancebg ${
          addSpacing ? "changeStyle" : ""
        }`}
      >
        <div>
          {pageStatus === "1" ? (
            <Grid
              container
              spacing={3}
              className="row"
              sx={{ justifyContent: "center" }}
            >
              <Grid xs={12} sx={{ textAlign: "center" }}>
                <img src="/images/logo.svg" alt="bima-street" height="60px" />
                <h6 className="pageHeading mt-10">
                  Your quest for <span className="best">Best</span>
                  <span>Home Insurance</span> ends here
                </h6>
                <p className="heading_desc mb-3">
                  Protecting your home just got easier with our top-rated home
                  insurance solutions.
                </p>
              </Grid>
              <Grid xs={12}>
                <>
                  <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: "center" }}
                    className="mb-3"
                  >
                    <Grid xs={12}>
                      <h4 style={{ textAlign: "center", color: "#203864" }}>
                        Enter Resident Details
                      </h4>
                    </Grid>
                    <Grid xs={4}>
                      <Box
                        sx={{
                          boxShadow: "0px 0px 10px 0px #00000026",
                          padding: "12px",
                          borderRadius: "6px",
                        }}
                      >
                        <p className="toinsure" style={{ textAlign: "center" }}>
                          {" "}
                          You are?
                        </p>
                        <ul
                          className="varient_list"
                          style={{
                            marginTop: "8px",
                            textAlign: "center",
                            marginBottom: 0,
                          }}
                        >
                          <li
                            onClick={() => setYouAreStatus("tenant")}
                            className={
                              youAreStatus === "tenant" ? "active" : ""
                            }
                          >
                            Tenant
                          </li>
                          <li
                            onClick={() => setYouAreStatus("owner")}
                            className={youAreStatus === "owner" ? "active" : ""}
                          >
                            Owner
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                    <Grid xs={4}>
                      <Box
                        sx={{
                          boxShadow: "0px 0px 10px 0px #00000026",
                          padding: "12px",
                          borderRadius: "6px",
                        }}
                      >
                        <p className="toinsure" style={{ textAlign: "center" }}>
                          {" "}
                          You Have?
                        </p>
                        <ul
                          className="varient_list"
                          style={{
                            marginTop: "8px",
                            textAlign: "center",
                            marginBottom: 0,
                          }}
                        >
                          <li
                            onClick={() => setYouHaveStatus("flat")}
                            className={youHaveStatus === "flat" ? "active" : ""}
                          >
                            Flat
                          </li>
                          <li
                            onClick={() => setYouHaveStatus("independentHouse")}
                            className={
                              youHaveStatus === "independentHouse"
                                ? "active"
                                : ""
                            }
                          >
                            Independent House
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      justifyContent: "center",
                      maxWidth: "624px",
                      margin: "0 auto",
                    }}
                  >
                    <Grid xs={12} textAlign={"center"}>
                      <Buttons
                        class_name="greenXlBtn"
                        onClickFunction={() => setPageStatus("2")}
                        text_name="Continue"
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          ) : pageStatus === "2" ? (
            <Grid
              container
              spacing={3}
              className="row"
              sx={{ justifyContent: "center" }}
            >
              <Grid xs={12} sx={{ textAlign: "center" }}>
                <h6 className="pageHeading">Sum Insured Values</h6>
              </Grid>
              <Grid xs={12}>
                <>
                  <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: "center" }}
                    className="mb-1"
                  >
                    <Grid xs={12}>
                      <ul
                        className="varient_list"
                        style={{
                          marginTop: "8px",
                          textAlign: "center",
                          marginBottom: 0,
                        }}
                      >
                        <li
                          onClick={() => setSIvalueTypes("building")}
                          className={
                            siValueTypes === "building" ? "active" : ""
                          }
                        >
                          Building
                        </li>
                        <li
                          onClick={() => setSIvalueTypes("household")}
                          className={
                            siValueTypes === "household" ? "active" : ""
                          }
                        >
                          Household
                        </li>
                        <li
                          onClick={() =>
                            setSIvalueTypes("buildingHousholdItems")
                          }
                          className={
                            siValueTypes === "buildingHousholdItems"
                              ? "active"
                              : ""
                          }
                        >
                          Building + Household Items
                        </li>
                      </ul>
                    </Grid>
                    <Grid xs={12}>
                      <h4
                        style={{
                          textAlign: "center",
                          color: "#203864",
                          fontWeight: 400,
                        }}
                      >
                        {siValueTypes === "building"
                          ? "Enter Value of Building & Carpet Area"
                          : siValueTypes === "household"
                          ? "Enter Value of Household Items"
                          : "Enter Value of Building, Household Items & Carpet Area"}
                      </h4>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      justifyContent: "center",
                      maxWidth: "624px",
                      margin: "0 auto",
                    }}
                  >
                    {siValueTypes === "building" ? (
                      <>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title={"Value of Building (in Rs)"}
                            value={""}
                            attrName={""}
                            value_update={() => {}}
                            warn_status={false}
                            error_message={"Enter Value of Building (in Rs)"}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title={"Carpet Area (Sq. ft)"}
                            value={""}
                            attrName={""}
                            value_update={() => {}}
                            warn_status={false}
                            error_message={"Enter Carpet Area (Sq. ft)"}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title={"Cost of Construction (per sq. ft.)"}
                            value={""}
                            attrName={""}
                            value_update={() => {}}
                            warn_status={false}
                            error_message={
                              "Enter Cost of Construction (per sq. ft.)"
                            }
                          />
                        </Grid>
                      </>
                    ) : siValueTypes === "household" ? (
                      <Grid xs={6}>
                        <RKTextField
                          class_name="inputField"
                          title={"Value of Household Items (in Rs)"}
                          value={""}
                          attrName={""}
                          value_update={() => {}}
                          warn_status={false}
                          error_message={"Enter Value of Building (in Rs)"}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title={"Value of Building (in Rs)"}
                            value={""}
                            attrName={""}
                            value_update={() => {}}
                            warn_status={false}
                            error_message={"Enter Value of Building (in Rs)"}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title={"Carpet Area (Sq. ft)"}
                            value={""}
                            attrName={""}
                            value_update={() => {}}
                            warn_status={false}
                            error_message={"Enter Carpet Area (Sq. ft)"}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title={"Value of Household Items (in Rs)"}
                            value={""}
                            attrName={""}
                            value_update={() => {}}
                            warn_status={false}
                            error_message={"Enter Value of Building (in Rs)"}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title={"Cost of Construction (per sq. ft.)"}
                            value={""}
                            attrName={""}
                            value_update={() => {}}
                            warn_status={false}
                            error_message={
                              "Enter Cost of Construction (per sq. ft.)"
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      justifyContent: "center",
                      maxWidth: "624px",
                      margin: "0 auto",
                    }}
                  >
                    <Grid xs={12} textAlign={"center"}>
                      <Buttons
                        class_name="greenXlBtn"
                        onClickFunction={() => setPageStatus("3")}
                        text_name="Continue"
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={3}
              className="row"
              sx={{ justifyContent: "center" }}
            >
              <Grid xs={12}>
                <>
                  <Grid
                    container
                    spacing={3}
                    className="row"
                    sx={{ justifyContent: "center" }}
                  >
                    <Grid xs={12} sx={{ textAlign: "center" }}>
                      <h6 className="pageHeading">Personal Details</h6>
                      <p className="heading_desc">
                        Please provide your relevant information so we can
                        assist you effectively, if needed.
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      justifyContent: "center",
                      margin: "24px auto",
                      maxWidth: "624px",
                    }}
                  >
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Full Name"}
                        value={""}
                        attrName={"name"}
                        value_update={() => {}}
                        warn_status={false}
                        error_message="Enter Full Name"
                        validation_type="NAME"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Email"}
                        value={""}
                        attrName={"name"}
                        value_update={() => {}}
                        warn_status={false}
                        error_message="Enter Valid Email"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        validation_type="NUMBER"
                        max_length={10}
                        class_name="inputField"
                        title={"Mobile"}
                        value={""}
                        attrName={""}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Enter valid mobile number"}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        validation_type="NUMBER"
                        max_length={6}
                        class_name="inputField"
                        title={"Pincode"}
                        value={""}
                        attrName={""}
                        value_update={() => {}}
                        warn_status={false}
                        error_message={"Enter valid pincode"}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid xs={12} textAlign={"center"}>
                      <Buttons
                        class_name="greenXlBtn"
                        onClickFunction={() => {}}
                        text_name="View Quotes"
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      className="disabledCheckbox"
                      textAlign={"center"}
                    >
                      <CustomCheckbox
                        label={
                          <>
                            By clicking on view quotes you agree to all{" "}
                            <a
                              onClick={() =>
                                window.open(
                                  COMMON_ROUTES.TERM_AND_CONDTION,
                                  "_blank"
                                )
                              }
                            >
                              Terms & Conditions
                            </a>
                            .
                          </>
                        }
                        defaultChecked
                        value={true}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </Box>
  );
};

export default HomeInsuranceForm;
